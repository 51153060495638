<template>
    <div class="app-container">
        <el-row :gutter="10">
            <el-col :span="8">
                <el-card shadow="hover" :body-style="{padding: '0px'}">
                    <div class="grid-content grid-con-red">
                        <i class="grid-con-icon el-icon-s-finance">
                        </i>
                        <div class="grid-cont-right">
                            <el-row>
                                <div><span>未支付评审费： </span><span class="moneyNum"> {{unwithdraw.expertBalance}} 元</span>
                                </div>
                                <div><span>未支付论文数： </span><span class="moneyNum"> {{unwithdraw.paperNum}} 篇</span></div>
                            </el-row>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card shadow="hover" :body-style="{padding: '0px'}">
                    <div class="grid-content grid-con-green">
                        <i class="el-icon-wallet grid-con-icon"></i>
                        <div class="grid-cont-right">
                            <div><span>已支付评审费： </span><span class="moneyNum"> {{withdrawed.expertBalance}} 元</span>
                            </div>
                            <div><span>已支付论文数： </span><span class="moneyNum"> {{withdrawed.paperNum}} 篇</span></div>
                            <!-- <div style="margin-top:10px">
                                <el-button type="primary" size="mini">提现</el-button>
                            </div> -->
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <div class="secord">
            <el-card>
                <div style="padding-left:15px;">
                    <el-form :inline="true" :model="search" ref='search' class="demo-form-inline">
                        <el-form-item label="评审日期:" prop="time">
                            <el-date-picker v-model="search.time" size="small" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="费用状态" prop="payState">
                            <el-select v-model="search.payState" placeholder="请选择费用状态" clearable size="small">
                                <el-option label="未支付" value="0"></el-option>
                                <el-option label="已支付" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item style="margin-left:10px;">
                            <el-button type="primary" size="mini" icon="el-icon-search" @click="SearchBillHandle">
                                搜索
                            </el-button>
                            <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
                                重置
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <!-- 表格 -->
                <div>
                    <!-- <vxe-toolbar perfect style="background-color:#fff;padding-left:10px;">
                        <template #buttons>
                            <el-button type="success" size="small" @click="sendBillHandle">提现</el-button>
                            </el-button>
                        </template>
                    </vxe-toolbar> -->
                    <!-- :loading="loading" -->
                    <vxe-table :header-cell-style="headerCellStyle" :cell-style='cellStyle' border stripe round
                        highlight-hover-row align="center" ref="paperTable" :data="billList" :loading='loading'
                        :checkbox-config="{checkMethod :checkMethod,trigger: 'row', highlight: true, range: true}"
                        class="mytable-scrollbar finance-table">
                        <!-- <vxe-column type='checkbox' title="全选" width="6%" :show-overflow="'tooltip'"></vxe-column> -->
                        <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
                        <vxe-column field="paperName" title="题目" min-width="400" :show-overflow="'tooltip'">
                        </vxe-column>
                        <vxe-column field="paperType" title="论文类型" min-width="200" :show-overflow="'tooltip'">
                        </vxe-column>
                        <vxe-column field="reviewTime" title="评审日期" min-width="200" :show-overflow="'tooltip'" sortable>
                            <template #default='{row}'>
                                <div>
                                    {{row.reviewTime}}
                                    <!-- {{row.reviewTime.substr(0,10)}} -->
                                </div>
                            </template>
                        </vxe-column>
                        <vxe-column field="reward" title="评审费用" min-width="200" :show-overflow="'tooltip'" sortable>
                            <template #default='{row}'>
                                <div>
                                    {{row.reward}} 元
                                </div>
                            </template>
                        </vxe-column>
                        <vxe-column field="payStatue" title="费用状态" min-width="200" :show-overflow="'tooltip'" sortable>
                            <template #default='{row}'>
                                {{payStatue[row.payStatue].text}}
                            </template>
                        </vxe-column>
                    </vxe-table>
                    <vxe-pager perfect align='right' :current-page.sync="page.currentPage"
                        :page-size.sync="page.pageSize" :total="page.total"
                        :page-sizes="[10, 20, 100, 1000, {label: '全量数据', value: page.total}]"
                        :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                        @page-change="handlePageChange" >
                        <template #left>
                            <vxe-button size="small" @click="firstPage">首页
                            </vxe-button>
                        </template>
                    </vxe-pager>
                </div>
            </el-card>
        </div>
        <!-- 打款弹窗 -->
        <el-dialog :visible.sync="sendBillDiaVis" :close-on-click-modal="false" width="50%" class='dialog'>
            <template slot="title">
                <div class='titleDia'>
                    账单详情
                </div>
            </template>
            <div style="margin:0px 20px;">
                <!-- <vxe-table :header-cell-style="headerCellStyle" border stripe round highlight-hover-row align="center"
                    ref="sendBillTable" :data="sendBillList">
                    <vxe-column field="paperNum" title="论文数量" width="50%"></vxe-column>
                    <vxe-column field="totalFee" title="总评审费" width="50%"></vxe-column>
                </vxe-table> -->
                <el-descriptions :column="2" :size="'medium'" :labelStyle="{'width':'15%'}"
                    :contentStyle="{'width':'35%'}" colon border>
                    <el-descriptions-item label="支付方式" :span='2'>{{payMethod[sendBillList.defaultPayType].method}}
                    </el-descriptions-item>
                    <el-descriptions-item label="开户人">{{sendBillList.name}}</el-descriptions-item>
                    <el-descriptions-item :label="payMethod[sendBillList.defaultPayType].label">
                        {{sendBillList.identity}}</el-descriptions-item>
                    <el-descriptions-item label="论文数量">{{sendBillList.paperNum}} 篇</el-descriptions-item>
                    <el-descriptions-item label="提现总额">{{sendBillList.totalFee}} 元</el-descriptions-item>
                    <el-descriptions-item label="提现状态" :span='2'><span
                            :class="sendBillList.statue == '200'?'green':'red'">{{innerPayStatue[sendBillList.statue]}}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="提现验证码" :span='2'>
                        <el-input v-model="yzm" style="width:150px;" size='small'></el-input>

                        <el-button type="primary" plain class="yzmBtn" :disabled='!showBtn' size="small"
                            @click="getCode">
                            {{showBtn?'点击获取验证码':second+'秒后重新获取'}}
                        </el-button>
                    </el-descriptions-item>
                </el-descriptions>
                <span style="color:#E6A23C;font-size:12px;">注：请您核对提现信息，若提现账号有误请前往个人中心修改。</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="sendBillDiaVis = false" size="small">取 消</el-button>
                <el-button type="primary" @click="confirmSendBill" size="small" :disabled='sending'>提现</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    // import dateFormat from '../../../../public/tools/date'
    export default {
        name: 'expertFinancial',
        data() {
            return {
                loading: false,
                expertId: '',
                payMethod: {
                    0: {
                        method: '支付宝',
                        label: '支付宝号',
                    },
                    1: {
                        method: '微信',
                        label: '微信号',
                    },
                    2: {
                        method: '银行卡',
                        label: '银行卡号',
                    },
                },
                payStatue: {
                    0: {
                        text: '未支付',
                        color: 'red',
                    },
                    1: {
                        text: '已支付',
                        color: 'green',
                    }
                },
                search: {
                    time: ['', ''],
                    payState: ''
                },
                // 分页
                page: {
                    currentPage: 1,
                    pageSize: 10,
                    pageCount: 40, //自己用
                    pageSizes: [1, 5, 10, 15, 1000],
                    total: 0
                },
                // 账单列表
                billList: [],
                unwithdraw: {
                    expertBalance: 0,
                    paperNum: 0
                },
                withdrawed: {
                    expertBalance: 0,
                    paperNum: 0
                },
                //发送账单
                sendBillDiaVis: false,
                sendBillList: {
                    name: '',
                    defaultPayType: 0,
                    identity: '',
                    paperNum: 0,
                    totalFee: 0,
                    yzm: ''
                },
                sendBill: {},
                innerPayStatue: {
                    '200': '已支付',
                    '201': '待支付',
                    '5001': '单笔额度超限',
                    '5002': '账户余额不足',
                    '5003': '账号信息错误',
                    '5004': '日累计额度超限',
                    '5005': '其他未知错误'
                },
                sending: false,
                showBtn: true,
                timer: null,
                second: 0,
                yzm: ''
            }
        },
        created() {
            // this.expertId = sessionStorage.getItem("expertId");
            this.getUnwithdraw();
            this.getWithdraw();
            this.getPaperList();
        },
        methods: {
            getUnwithdraw() {
                this.$api.finance.getWithdrawn()
                    .then(res => {
                        if (res.data.code == 200) {
                            this.withdrawed = res.data.data;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            getWithdraw() {
                this.$api.finance.getExpertBalance()
                    .then(res => {
                        if (res.data.code == 200) {
                            this.unwithdraw = res.data.data;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            getPaperList() {
                this.loading = true;
                let param = new URLSearchParams();
                param.append("startTime", this.search.time[0] == '' ? '' : this.dateFormat(this.search.time[0]));
                param.append("endTime", this.search.time[1] == '' ? '' : this.dateFormat(this.search.time[1]));
                param.append('payState', this.search.payState);
                param.append("pageIndex", this.page.currentPage);
                param.append("pageSize", this.page.pageSize);
                this.$api.finance.getExpertBills(param)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.billList = res.data.data;
                            this.page.total = res.data.count;
                            this.loading = false;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })

            },
            SearchBillHandle() {
                this.getPaperList();
            },
            resetHandle() {
                this.search = {
                    time:['',''],
                    payState:''
                }
            },
            //获取验证码
            getCode() {
                if (!this.timer) {
                    let param = new URLSearchParams();
                    param.append("expertId", this.expertId);
                    this.second = 60;
                    this.showBtn = false;
                    this.$axios.get('/sms/send_withdraw_verification_code', {
                            params: param
                        }).then(res => {
                            if (res.data.data == 0) {
                                this.$message.warning("获取失败!");
                            }
                        })
                        .catch(err => {
                            this.$message.warning("服务器维护!");
                        })
                    this.timer = setInterval(() => {
                        console.log(this.second);
                        if (this.second > 0) {
                            this.second--;
                        } else {
                            this.showBtn = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }
            },
            //打款请求
            sendBillHandle() {
                this.sending = false;
                let seleted = this.$refs.paperTable.getCheckboxRecords();
                if (seleted.length <= 0) {
                    this.$message.warning("请选择论文!");
                    return;
                }
                let param = new URLSearchParams();
                param.append("expertId", this.expertId);

                this.$axios.get("/finance/get_expert_account_info", {
                    params: param
                }).then(res => {
                    let paperNum = 0,
                        totalFee = 0;
                    let reviewIds = [],
                        isRes = [];
                    seleted.forEach(item => {
                        paperNum += 1;
                        totalFee += item.reward;
                        reviewIds.push(item.reviewId);
                        isRes.push(item.isRe);
                    });
                    this.sendBillList = {
                        name: res.data.data.name,
                        defaultPayType: res.data.data.defaultPayType,
                        identity: res.data.data.identity,
                        paperNum: paperNum,
                        totalFee: totalFee,
                        statue: '201'
                    };
                    this.sendBill = {
                        reviewIds: reviewIds.join(','),
                        isRes: isRes.join(','),
                    };
                    this.sendBillDiaVis = true;
                }).catch(err => {
                    this.$message.warning('服务器维护！');
                });


            },
            //确认打款
            confirmSendBill() {
                if (this.yzm == '') {
                    this.$message.warning("请输入验证码");
                    return;
                }
                let loading = this.$loading({
                    lock: true,
                    text: '打款中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let param = {
                    expertId: this.expertId,
                    reviewIds: this.sendBill.reviewIds,
                    isRes: this.sendBill.isRes,
                    withdrawType: '0',
                    code: this.yzm
                };
                console.log(param);
                this.$axios.post('/finance/transfer_to_expert_account', param)
                    .then(res => {
                        this.sendBillList.statue = res.data.code;
                        this.sending = true;
                        loading.close();
                        if (res.data.code == 200) {
                            this.$message.success("打款成功！");
                            this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
                            this.getUnwithdraw();
                            this.getWithdraw();
                            this.sendBillDiaVis = false;
                        } else {
                            this.$message.warning("打款失败！");
                        }
                    })
                    .catch(err => {
                        loading.close();
                        this.$message.warning("服务器维护！");
                    })
            },
            //分页操作
            handlePageChange({
                currentPage,
                pageSize
            }) {
                console.log(currentPage, pageSize);
                this.page.currentPage = currentPage;
                this.page.pageSize = pageSize;
                this.getPaperList();
            },
            //每页多少数据改变
            handleChangePageSize(val) {
                this.page.pageSize = val;
                this.page.currentPage = 1;
                this.getPaperList();
            },
            firstPage() {
                this.page.currentPage = 1;
                this.getPaperList();
            },
            //设置不可选
            checkMethod({
                row
            }) {
                console.log(row.payStatue);
                return row.payStatue == 0;
            },
            //头部样式
            headerCellStyle({
                row,
                column,
                rowIndex,
                columnIndex
            }) {
                return "background-color:#e0e0e0f1"
            },
            //设置单元格样式
            cellStyle({
                row,
                rowIndex,
                column,
                columnIndex
            }) {
                //设置状态颜色
                let styles = {};
                if (column.property == 'payStatue') {
                    styles['color'] = this.payStatue[row.payStatue].color;
                }
                return styles;
            },
            dateFormat: function (time) {
                var datetime = new Date(time);
                // datetime.setTime(time);
                var year = datetime.getFullYear();
                var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() +
                    1;
                var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
                return year + "-" + month + "-" + date;
            },
        },
        components: {

        }
    }
</script>

<style scoped>
    .grid-content {
        display: flex;
        align-items: center;
        height: 100px;
    }

    .grid-cont-right {
        position: relative;
        flex: 1;
        text-align: center;
        font-size: 14px;
        color: #999;
    }

    .grid-num {
        font-size: 30px;
        font-weight: bold;
    }

    .grid-con-icon {
        font-size: 50px;
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        color: #fff;
    }

    .grid-con-red .grid-con-icon {
        background: rgb(255, 98, 62);
        border-right: 1px solid rgb(255, 98, 62);
    }

    .grid-con-green .grid-con-icon {
        background: rgb(100, 213, 114);
    }

    .grid-con-red .moneyNum {
        color: rgb(255, 98, 62);
        font-size: 20px;
    }

    .grid-con-green .moneyNum {
        color: rgb(100, 213, 114);
        font-size: 20px;
    }

    .secord {
        margin-top: 10px;
    }

    .yzmBtn {
        margin-left: 5px;
    }

    
</style>

<style>
    .secord .el-card__body {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .green {
        color: green;
    }

    .red {
        color: #F56C6C
    }

    .finance-table .vxe-table--body-wrapper {
        height: calc(100vh - 410px);
    }
</style>